<template>
  <div class="inline-block">
    <ColorTextBtn class="line" @click="deleteShop"> 删除 </ColorTextBtn>
  </div>
</template>

<script>
import { deleteShop as del } from '@/api/shop/manageApi'
export default {
  props: {
    initData: {
      type: Object,
      default: () => {
        return {}
      }
    },
    sup_this: {
      type: Object,
      default: null
    }
  },
  data() {
    return {}
  },
  methods: {
    deleteShop() {
      this.$confirm('确定删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          del({ idList: [this.initData.id] }).then((res) => {
            if ($SUC(res)) {
              this.$message.success('删除成功')
              if (this.sup_this) {
                this.sup_this.init()
              }
            }
          })
        })
        .catch(() => {
          this.$message.info('已取消')
        })
    }
  }
}
</script>

<style lang="scss" scoped></style>
